<template>
    <div class="row">
        <div class="col-md-7">
            <adjuntos ref="adjuntos" @archivoSeleccionado="actualizarArchivosSeleccionados" @informeSeleccionado="actualizarInformesSeleccionados" :id="id" :servicio="servicio" :visitas="visitas" />
        </div>
        <div class="col-md-5">
            <email ref="email" @enviar="enviarCorreo" :id="id" :servicio="servicio" :visitas="visitas" :archivosadjuntar="archivosadjuntar" />
        </div>
    </div>
</template>
<script>
import adjuntos from './adjuntos.vue';
import email from './email.vue';
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id','servicio'],
    setup() {
        return;
        
    },
    components: {
        adjuntos,
        email
    },
    data() {
        return {
            visitas: [],
            archivosadjuntar:[],
            informesadjuntar:[],
        }
    },
    methods: {
        async enviarCorreo(datos) {
            if(this.archivosadjuntar.length >0){
                datos.adjuntos = this.archivosadjuntar;
            }
            const api = new PwgsApi();
            var informes =[];
            for(var informe of this.informesadjuntar){
                informes.push(informe.documento);
            }
            datos.adjuntos = informes;
            const resp = await api.put('servicios/'+this.id+'/enviar-email',datos);
            if(resp == "OK"){
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Correo enviado con éxito.', life: 2000 });
                this.$refs.email.reset();
                this.$refs.adjuntos.reset();
            }else{
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar el correo.', life: 5000 });
            }
        },
        async cargarvisitas() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/visitas
            this.visitas = [];
            const api = new PwgsApi();
            this.visitas = await api.get('servicios/' + this.$props.id + '/visitas');
        },
        actualizarArchivosSeleccionados(archivos){
            this.archivosadjuntar = archivos;
            console.log('archvssubir',this.archivosadjuntar);
        },
        actualizarInformesSeleccionados(informe){
            this.informesadjuntar = informe;
            console.log('informessubir',this.informesadjuntar);
        },
    },
    watch: {
        id() {
            this.cargarvisitas();

        }
    },
    mounted() {
        this.cargarvisitas();

    },
})



</script>