<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.correos') }}</h3>
        </div>
        <div class="card-body">
            <h4 style="text-align:center">{{ $t('general.enviar') }} email</h4>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label >{{ $t('general.destinatarios') }}</label><br> 
                        <MultiSelect v-model="emailsenviar" :options="emails" display="chip" optionGroupLabel="tipo" optionGroupChildren="email" placeholder="Selecciona emails" :maxSelectedLabels="3"  >
                            <template #option="slotProps">
                                <div>{{ slotProps.option }}</div>
                            </template>
                        </MultiSelect>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label >{{ $t('general.otrosdestinatarios') }}</label>
                        <input type="text" v-model="otrosdestinatarios" placeholder="Otros destinatarios, separados por ;" class="form-control">
                    </div>                 
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label >{{ $t('general.asunto') }}</label>
                        <input type="text" v-model="asunto" class="form-control">
                    </div>                 
                </div>
            </div>
            <div style="display: grid;margin-bottom: 20px;">
                <label for="">Metodo de envío</label>
                <div style="display: flex; gap: 5px;">
                    <label style="margin: 0;" for="metodo1">CC</label>
                    <input id="metodo1" v-model="metodo" value="CC" type="radio">
                </div>
                <div style="display: flex; gap: 5px;">
                    <label style="margin: 0;" for="metodo2">BCC</label>
                    <input id="metodo2" v-model="metodo" value="BCC" type="radio">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea placeholder="Contenido" rows="10" v-model="mensaje" class="form-control"></textarea>
                    </div>                 
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-success btn-block" @click="enviaremails()" type="button">{{ $t('general.enviar') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import MultiSelect from 'primevue/multiselect';
export default ({
    props: ['id','servicio','visitas','archivosadjuntar'],
    data() {
        return {
            emails: [],
            emailsenviar: '',
            asunto: '', 
            mensaje: '',
            otrosdestinatarios: '',
            destinararios:[],
            metodo: 'CC',
        }
    },
    setup() {
        return;
    },
    components: {
        MultiSelect
    },
    methods: {
        reset(){
            this.emails = [];
            this.emailsenviar = '';
            this.asunto = '';
            this.mensaje = '';
            this.otrosdestinatarios = '';
            this.destinararios = [];
            this.metodo = 'CC';
            this.cargaremails();
        },
       async cargaremails() {           
            //GET  modulos/pwgsapi/index.php/servicios/:id/emails
            const api = new PwgsApi();
            let aux;
            aux = await api.get('servicios/' + this.$props.id + '/emails');
            for (let i = 0; i < aux.length; i++){
                this.emails[i] = { tipo:aux[i].tipo,email:[] };
                if (aux[i].emails) {
                    for (let j = 0; j < aux[i].emails.length; j++) {
                        this.emails[i].email[j] = aux[i].emails[j].email;
                    }
                }
            }
        }, 
        async enviaremails() {
            //PUT  modulos/pwgsapi/index.php/servicios/:id/enviar-email
            let todoscorreos = [];
            let correossubida = [];
            if (this.otrosdestinatarios) {
                todoscorreos = this.otrosdestinatarios.split(";");
            }
            correossubida = this.emailsenviar.concat(todoscorreos);
            let subidadatos = { tipo_envio: 'adjuntos_seguimiento', asunto: this.asunto, mensaje: this.mensaje,emails:correossubida,metodo_envio:this.metodo};
            this.$emit('enviar', subidadatos);
        },
    },
    mounted() {
        this.cargaremails();
    },
    watch: {
        id() {
            this.cargaremails();
        },
        archivosadjuntar() {
            
        },
        servicio(){
            this.asunto = 'Expediente '+this.servicio.codigo_original;
        }
    }
})
</script>
<style>
.p-multiselect {
    width: 100%;
}
</style>